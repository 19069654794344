<template>
  <div v-if="selectedSearch" id="text-search-setup" class="vap-page">
    <div class="inner-div">
      <div class="vap-page-header">
        <VasionArrowLeftIcon id="back-arrow" class="left vasion-cursor back-arrow" @click="backToSearchSetup" />
        <h1 id="section-headline"> {{ searchName }} </h1>
        <div class="btns-container">
          <VasionButton
            id="cancel-button"
            class="add-cancel"
            name="cancel-button"
            classProp="text"
            @vasionButtonClicked="backToSearchSetup"
          >
            CANCEL
          </VasionButton>
          <VasionButton
            id="add-button"
            class="add-cancel last-btn"
            name="add-button"
            classProp="primary"
            :isDisabled="!isDirty"
            @vasionButtonClicked="saveFullTextSearch"
          >
            {{ addButtonText }}
          </VasionButton>
        </div>
      </div>
      <div class="setup-row">
        <VasionInput
          id="search-name"
          v-model="searchName"
          class="tss-vasion-input"
          title="SEARCH NAME"
          name="search-name"
          placeholder="Search Name..."
          inputType="top-white"
          :required="true"
          :isInErrorState="needsInput"
          @input="checkNeedsInput"
        />

        <VasionInput
          id="search-text"
          v-model="staticSearchText"
          class="tss-vasion-input"
          title="SEARCH TEXT"
          name="search-text"
          placeholder="Search Text..."
          inputType="top-white"
          @input="isDirty = true"
        />
      </div>

      <div class="setup-row">
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedCustomizeFolder"
          :dataArray="folderSearchConfigurations"
          width="100%"
          title="CUSTOMIZED FOLDER VIEW"
          type="plain-list"
          displayName="name"
          valueName="id"
          @input="isDirty = true"
        >
          {{ slotItem.item.name }}
        </VasionDropList>
      </div>

      <div class="setup-row">
        <div id="table-label-pii">
          <span id="table-label">FOLDERS</span>
          <VasionCheckbox
            id="pii-data"
            class="tss-vasion-checkbox"
            name="pii-data"
            :checked="piiDataModel"
            @change="togglePiiData()"
          >
            Contains PII Data
          </VasionCheckbox>
        </div>
        <div v-if="dataTable && dataTable.Columns" class="main-table-container vasion-html-table-default textsearch-table-container">
          <VasionTable
            id="search-folders"
            v-model="searchInFolders"
            :headerColumns="dataTable.Columns.Values"
            :tableRows="dataTable.Rows.Values"
            :hideColumns="hideColumns"
            @vasion-row-checked="handleRowCheckChange"
          />
        </div>
      </div>
    </div>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="full-text-search-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
export default {
  name: 'TextSearchSetup',
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  props: {
    searchID: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: function () {
    return {
      dataTable: {},
      hideColumns: ['_VaultID_'],
      isDirty: false,
      needsInput: false,
      openInNativeFormat: false,
      piiDataModel: false,
      searchInFolders: [],
      searchName: '',
      selectedCustomizeFolder: {},
      showLeaveDialog: false,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      staticSearchText: '',
    }
  },
  computed: {
    addButtonText() {
      let text = 'UPDATE'
      if (this.searchID === 0) {
        text = 'ADD'
      }

      return text
    },
    folderSearchConfigurations() { return this.$store.state.search.folderSearchConfigurations },
    selectedSearch() { return this.$store.state.search.editSearch },
    vaults() {
      return this.$store.state.vault.vaultList.map((vault) => {
        return {
          name: vault.vaultName,
          value: vault.vaultID,
        }
      })
    },
  },
  async created() {
    if (typeof this.searchID === 'undefined'
        || (!this.folderSearchConfigurations || this.folderSearchConfigurations.length < 1)
        || (!this.vaults || this.vaults.length < 1)) {
      this.backToSearchSetup()
    }

    await this.$store.dispatch('search/loadFullTextSearchDetails', this.searchID)
    this.searchName = this.selectedSearch.searchName
    this.piiDataModel = this.selectedSearch.piiData

    const foundCustFolder = this.folderSearchConfigurations.find((element) => {
      return element.id === this.selectedSearch.customizedFolderID
    })

    if (foundCustFolder) {
      this.selectedCustomizeFolder = foundCustFolder
    } else if (this.folderSearchConfigurations.length > 0) {
      // eslint-disable-next-line
      this.selectedCustomizeFolder = this.folderSearchConfigurations[0]
    }

    this.openInNativeFormat = this.selectedSearch.openInNativeFormat
    this.staticSearchText = this.selectedSearch.staticSearchText
    this.searchInFolders = this.selectedSearch.searchInFolders

    this.buildDataTable()
  },
  methods: {
    backToSearchSetup() { this.$router.push({ name: 'AdminFullTextSearchList' }) },
    buildDataTable() {
      const data = {
        Columns: {
          Values: [
              '_VasionCheckBox_',
              '_VaultID_',
              'Folder Name',
          ],
          ValueType: 'String',
        },
        Rows: {
          Values: [],
          ValueType: 'ApiArray`1',
        },
      }

      const folders = this.searchInFolders
      data.Rows.Values = this.vaults.map((vault) => {
        let checked = false
        if (folders) {
          checked = folders.includes(vault.value)
        }

        return {
          Values: [
            { show: true, value: checked },
            vault.value,
            vault.name,
          ],
          ValueType: 'String',
        }
      })

      this.dataTable = data
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    checkNeedsInput(e) { 
      this.needsInput = !e
      this.isDirty = true 
    },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    handleRowCheckChange(checkedRows) {
      if (checkedRows && checkedRows.length > 0) {
        this.searchInFolders = checkedRows.map((row) => {
            return row.Values[1] // The ID value is stored in the 2nd element
        })
      }
      this.isDirty = true
    },
    async saveFullTextSearch() {
      if (!this.searchName) {
        this.snackbarImage = false
        this.snackbarTitle = 'Missing Required Field'
        this.snackbarSubTitle = '- Text Input - Search Name'
        this.showSnackbarBool = true

        this.needsInput = true

        return
      }
      if (this.searchName.trim() === '') {
        this.snackbarImage = false
        this.snackbarTitle = 'Missing Required Field'
        this.snackbarSubTitle = '- Text Input - Search Name cannot only include spaces.'
        this.showSnackbarBool = true

        this.needsInput = true

        return
      }

      const payload = {
        searchID: this.searchID,
        searchName: this.searchName,
        piiData: this.piiDataModel,
        customizedFolderID: !this.selectedCustomizeFolder ? 0 : this.selectedCustomizeFolder.id,
        openInNativeFormat: this.openInNativeFormat,
        staticSearchText: this.staticSearchText,
        searchInFolders: this.searchInFolders,
      }

      const result = await this.$store.dispatch('search/saveFullTextSearch', payload)

      if (result.Value === "Success") {
        this.snackbarImage = true
        this.snackbarTitle = 'Save Successful'
        this.snackbarSubTitle = `Successfully saved: "${this.searchName}"`
        this.showSnackbarBool = true

        this.isDirty = false
        setTimeout(() => {
          this.$router.push({ name: 'AdminFullTextSearchList' })
        }, 1500);
      } else {
        this.snackbarImage = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = result.Value
        this.showSnackbarBool = true
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    togglePiiData() {
      this.piiDataModel = !this.piiDataModel
      this.isDirty = true;
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #text-search-setup {
    display: block;
    float: left;

    & .setup-row {
      display: block;
      float: left;
      width: 100%;
      margin: 6px 0;

      & #pii-data {
        margin: 12px;
      }

      & #table-label-pii {
        width: 100%;
        height: 35px;

        & #table-label {
          display: inline-block;
          margin-top: 10px;
          height: 12px;
          font-family: $font-family-medium, 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
          font-size: 12px;
          font-weight: 500;
          color: $grey-400;
          margin-bottom: 2px;
          bottom: 4px;
        }
      }
    }
  }

  .inner-div {
    margin: 10px 16px 0 16px;
  }

  .tss-vasion-input {
    display: block;
    float: left;
    margin-right: 6px;
    width: 48%;
  }

  .tss-vasion-checkbox {
    display: block;
    float: right;
    margin: 6px 6px 0 6px;
  }

  #section-headline {
    @include Headline;
  }

  .add-cancel {
    float: right;
  }

  .back-arrow {
    padding: 5px 5px 0 0;
  }

  .textsearch-table-container {
  height: calc(100vh - 428px);
  overflow: auto;
  text-align: left;
}

</style>
